import {
    combineReducers
  } from 'redux';
  

  
  const rootReducer = combineReducers({

  });
  
  export default rootReducer;